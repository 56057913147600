<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
-->

<template>
    <div>
        <!-- <img src="../assets/imgs/logo.png" style="width: 170px;height: 55px;display: block; margin: auto;" alt=""> -->
        <div style="width: 60%;margin: auto;" v-html="data">
        </div>
        <p class="copyright font-regular text-sm" style="text-align: center;">
            <span class="mr-10" v-for="(item, index) in config.copyright" :key="'copyright_' + index">{{ item }}</span>
        </p>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"

export default {
    components: {

    },
    data() {
        return {
            data: ''
        }
    },
    computed: {
        ...mapGetters("user", ["token"]),
        ...mapGetters("app", ["config"])
    },
    created() {
        this.richtext()
        document.title = '角色协议-网红秀-Ai网红生成工具'
        console.log('document.title',document.title);
    },
    methods: {
        // 获取AI类型
        richtext() {
            this.$http("drawing.richtext", { id: 7 }).then(res => {
                if (res.code === 1) {
                    // this.topic = res.data
                    console.log(res.data);
                    this.data = res.data.content
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.layout-default .ant-layout-header {
    display: none !important;
}
</style>
